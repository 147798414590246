import { HeroEntry, HeroFormFields } from './../typescript/super-hero-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { blobToFile, compressImage, convertIfNeeded } from '../helper';

const auth = {
  api_key: `${process.env.REACT_APP_CONTENTSTACK_API_KEY}`,
  authorization: `${process.env.REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN}`,
};

const automation_uid = process.env.REACT_APP_CONTENTSTACK_AUTOMATIONS_UID;
const automation_auth = {
  'ah-http-key': `${process.env.REACT_APP_CONTENTSTACK_AUTOMATIONS_API_KEY}`,
};

const parent_uid = `${process.env.REACT_APP_CONTENTSTACK_PARENT_ASSET_UID}`;

const API = process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  ? process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  : process.env.REACT_APP_CONTENTSTACK_API_HOST;

// const API = "api.contentstack.io"
// const APP = "app.contentstack.com"

const APP = process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  ? process.env.REACT_APP_CONTENTSTACK_PROXY_HOST
  : process.env.REACT_APP_CONTENTSTACK_APP_HOST;


type HeroAsset = {
  'asset[upload]': File;
  'asset[parent_uid]': string;
  'asset[title]': string;
  'asset[description]': string;
};

type HeroEntryHolder = {
  entry: HeroFormFields;
};

export default {
  async createHero(data: HeroFormFields) {
    if (!data?.gender_qualities) data["gender_qualities"] = [];
    const createdAsset = await this.createAsset(data);

    if (createdAsset.status === 201 || createdAsset.status === 200) {
      const entry = {
        ...data,
        title: uuidv4(),
        original_image: createdAsset?.data?.asset?.uid,
      };

      const createdEntry = await this.createEntry('superhero_form', {
        entry: entry,
      });

      // trigger automation
      const uid = createdEntry?.data?.entry?.uid;
      console.info('trigger auto with ', uid);

      const automation = await this.triggerAutomations(uid);
      console.info('automation', automation);
      // const automation = await this.triggerQueue(uid, 'superhero_form');
      return {
        entry: createdEntry?.data?.entry,
        accepted: automation?.data?.__accepted__,
        error: false,
      };
    }
    return { entry: null, accepted: false, error: true };
  },

  triggerQueue(entry_uid: string, content_type_uid: string) {
    return axios.post(`${process.env.REACT_APP_QUEUE_URL}`, {
      "entry_uid": entry_uid,
      "content_type_uid": content_type_uid,
      "stack_api_key": process.env.REACT_APP_CONTENTSTACK_API_KEY
    }, {
      headers: {
        "Content-Type": "application/json",
        'ah-http-key': `${process.env.REACT_APP_QUEUE_AUTOMATION_AUTH_KEY}`,
      }
    }
    );
  },

  createEntry(content_type_uid: string, entry: HeroEntryHolder) {
    return axios.post(`https://${API}/v3/content_types/${content_type_uid}/entries`, entry, {
      headers: { ...auth },
    });
  },

  updateEntry(entry_uid: string, update: HeroEntry, content_type_uid = 'superhero_form') {
    return axios.put(
      `https://${API}/v3/content_types/${content_type_uid}/entries/${entry_uid}`,
      { entry: update },
      {
        headers: { ...auth },
      },
    );
  },


  
  triggerAutomations(entry_uid: string, content_type_uid = 'superhero_form') {
    return axios.post(
      `https://${APP}/automations-api/run/${automation_uid}`,
      // "https://a33d-2409-40c2-1008-54ba-95c9-344d-ab91-ea9c.ngrok-free.app",
      {
        entry_uid: entry_uid,
        content_type_uid: content_type_uid,
        stack_api_key: process.env.REACT_APP_CONTENTSTACK_API_KEY
      },
      {
        headers: { ...automation_auth, 'Content-Type': 'application/json' },
        // headers: { 'Content-Type': 'application/json' },
      },
    );
  },



  async createAsset(asset: HeroFormFields) {
    let result: any = asset?.original_image?.[0];
    if ((result.size / 1024 / 1024) > 4) {
      const image = await convertIfNeeded(asset?.original_image?.[0]);
      result = await compressImage(image);
      result = await blobToFile(result, `hero-${asset?.title}`);
    } 
    const img: HeroAsset = {
      'asset[upload]': result,
      'asset[parent_uid]': parent_uid,
      'asset[title]': `hero-${asset?.title}`,
      'asset[description]': '',
    };
    const formData: FormData = new FormData();
    Object.entries(img).map(([key, value]) => formData.append(key, value));


    return axios.post(`https://${API}/v3/assets`, formData, {
      headers: {
        ...auth,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
    });
  },
};
