import heic2any from 'heic2any';
import Stack from '../sdk/entry';
import { addEditableTags } from '@contentstack/utils';
import Compressor from 'compressorjs';

const liveEdit = process.env.REACT_APP_CONTENTSTACK_LIVE_EDIT_TAGS === 'true';

export const getHeaderRes = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'header',
    referenceFieldPath: ['navigation_menu.page_reference'],
    jsonRtePath: ['notification_bar.announcement_text'],
  })) as any;
  liveEdit && addEditableTags(response[0][0], 'header', true);
  return response[0][0];
};

export const getFooterRes = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'footer',
    jsonRtePath: ['copyright'],
    referenceFieldPath: undefined,
  })) as any;
  liveEdit && addEditableTags(response[0][0], 'footer', true);
  return response[0][0];
};

export const getSocialRes = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'footer',
    jsonRtePath: ['copyright'],
    referenceFieldPath: undefined,
  })) as any;
  liveEdit && addEditableTags(response[0][0], 'footer', true);
  return response[0][0];
};

export const getMetaEntry = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'metadata',
    jsonRtePath: undefined,
    referenceFieldPath: undefined,
  })) as any;
  liveEdit && addEditableTags(response[0], 'page', true);
  return response[0][0];
}

export const getAllEntries = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'page',
    jsonRtePath: undefined,
    referenceFieldPath: undefined,
  })) as any;
  liveEdit && addEditableTags(response[0], 'page', true);
  return response[0];
};

export const getPageRes = async (entryUrl: string) => {
  const response = (await Stack.getEntryByUrl({
    contentTypeUid: 'page',
    entryUrl,
    referenceFieldPath: ['page_components.from_blog.featured_blogs'],
    jsonRtePath: [
      'page_components.from_blog.featured_blogs.body',
      'page_components.section_with_buckets.buckets.description',
      'page_components.section_with_html_code.description',
    ],
  })) as any;
  liveEdit && addEditableTags(response[0], 'page', true);
  return response[0];
};

export const getBlogListRes = async () => {
  const response = (await Stack.getEntry({
    contentTypeUid: 'blog_post',
    referenceFieldPath: ['author', 'related_post'],
    jsonRtePath: ['body'],
  })) as any;
  liveEdit && addEditableTags(response[0], 'blog_post', true);
  return response[0];
};

export const getHeroFormListRes = async (skip: number, limit: number, orderBy: string) => {
  const response = (await Stack.getAcceptedHeroesEntries({
    contentTypeUid: 'superhero_form',
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
    limit: limit,
    skip: skip,
    orderBy: orderBy,
  })) as any;
  liveEdit && addEditableTags(response[0], 'superhero_form', true);
  return response;
};


export const getHeroRandom = async (skip: number, limit: number) => {
  const response = (await Stack.getAllEntry({
    contentTypeUid: 'superhero_form',
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
    limit: limit,
    skip: skip,
  })) as any;
  liveEdit && addEditableTags(response[0], 'superhero_form', true);
  return response;
};


export const getMyHeroRes = async (id: string) => {
  const response = (await Stack.getEntryById({
    contentTypeUid: 'superhero_form',
    entryId: id,
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
  })) as any;
  liveEdit && addEditableTags(response[0], 'superhero_form', true);
  return response;
};

export const getBlogPostRes = async (entryUrl: string) => {
  const response = (await Stack.getEntryByUrl({
    contentTypeUid: 'blog_post',
    entryUrl,
    referenceFieldPath: ['author', 'related_post'],
    jsonRtePath: ['body', 'related_post.body'],
  })) as any;
  liveEdit && addEditableTags(response[0], 'blog_post', true);
  return response[0];
};

// TODO: remove  heic convert
// export async function convertIfNeeded(url: string) {
//   const ext = url.split('.')?.pop()?.toLocaleLowerCase();

//   if ((ext && ext === 'heic') || ext === 'heif') {
//     const result = await fetch(url)
//       .then((res) => res.blob())
//       .then((blob) => heic2any({ blob, toType: 'image/jpeg' }))
//       .then((conversionResult) => {
//         console.log('HEIC');
//         return URL.createObjectURL(conversionResult as Blob);
//       })
//       .catch(() => {
//         console.log('error');
//       });

//     return result;
//   } else return url;
// }

export async function convertIfNeeded(file: any) {

  if (file?.type === "image/heif" || file?.type === "image/heic") {
    const blob = new Blob([file], { type: file.type });
    const result = await heic2any({ blob, toType: 'image/jpeg' })
      .then((conversionResult: any) => {
        return conversionResult;
      })
      .catch((error) => {
        console.error('error in convertIfNeeded', error);
      });

    return result;
  } else return file;
}

export async function compressImage(image: any) {
  let quality = 0.8;
  const sizeInMB = image?.size / 1024 / 1024;
  if (sizeInMB > 5 && sizeInMB < 13) {
    quality = 0.6;
  }  else if(sizeInMB > 15 && sizeInMB < 20) {
    quality = 0.3;
  } else {
    quality = 0.1;
  }
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: quality,
      convertSize: 4000000,
      maxWidth: 1920,
      maxHeight: 1080,
      convertTypes: "image/jpg",
      success(result) {
        console.info(`Compressed image size: ${result.size / 1024 / 1024} MB`);
        resolve(result);
      },
      error(err) {
        console.error('Compressed image error:', err);
        reject(err);
      },
    });
  });
}


export async function blobToFile(theBlob: any, fileName: any){
  return new File([theBlob], `${fileName}.${theBlob?.type?.split('/')?.[1]}`, {
    type: theBlob.type,
    lastModified:new Date().getTime()
  });
}