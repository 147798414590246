import React, { useState, useEffect } from 'react';
import { getMetaEntry } from '../helper';


const withPasswordProtection = (WrappedComponent: any) => {
  const WithPasswordProtection = (props: any) => {
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
      const getMetaData = async () => {
        const entry: any = await getMetaEntry();
        const password = entry?.site_password || process.env.REACT_APP_COMPOSABLE_PASSWORD;
        const isAuthorizedInStorage = localStorage.getItem("isAuthorized");
        const passwordInStorage: any = localStorage.getItem("password");
        if (isAuthorizedInStorage === "true" && parseInt(passwordInStorage) === password) {
          setIsAuthorized(true);
        } else {
          const checkPassword = () => {
            const number: any = prompt("Enter password:", "");
            const passwordIsValid = parseInt(number) === password;
            if (passwordIsValid) {
              localStorage.setItem("isAuthorized", "true");
              localStorage.setItem("password", number);
              setIsAuthorized(true);
            } else {
              localStorage.removeItem("isAuthorized");
              localStorage.removeItem("password");
              setIsAuthorized(false);
              checkPassword();
            }
          }
          checkPassword();
        }
      }
      getMetaData()
    }, []);

    if (isAuthorized) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };

  return WithPasswordProtection;
};

export default withPasswordProtection;
