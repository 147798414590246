import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getHeroFormListRes } from "../helper";
import { HeroEntry } from "../typescript/super-hero-form";
import HeroView, { HERO_IMAGE } from "../components/hero-view";
import useLocalStorage from "use-local-storage";
import Pusher from 'pusher-js';
/* eslint-disable */
const PUSHER_API_KEY: any = process.env.REACT_APP_PUSHER_API_KEY;
const PUSHER_CLUSTER: any = process.env.REACT_APP_PUSHER_CLUSTER
const PUSHER_CHANNEL: any = process.env.REACT_APP_PUSHER_CHANNEL;
const PUSHER_EVENT: any = process.env.REACT_APP_PUSHER_EVENT;


export default function HeroPage() {
  const history = useNavigate();
  const [getEntry, setEntry] = useState({} as HeroEntry);
  const [error, setError] = useState(false);
  const [entries, setEntries] = useState([]);
  const [data, setRandomData] = useState({} as HeroEntry);
  const [start, setStart] = useState(false);
  const [_entries, _setEntries] = useState([]);
  const [intervalIDS, setIntervalIDS] = useState<any>([]);
  const [_intervalID, __setIntervalID] = useState<any>();

  if (!localStorage.getItem("Date")) {
    localStorage.setItem("Date", Date.now().toFixed());
  }

  async function fetchData(entriesLimit: number) {
    getHeroFormListRes(0, entriesLimit, "updated_at")
      .then(result => {
        if (entriesLimit > 1) {
          const rand = Math.floor(Math.random() * result[0]?.length)
          // setEntry(result[0]?.[rand])
          setEntries(result[0])
          setRandomData(result[0]?.[rand])
          return
        }
        setEntry(result[0][0])
      })
      .catch(error => {
        console.error(error);
        setError(true)
      })
  }


  useEffect(() => {
    const interval1 = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * entries.length);
      const randomItem = entries[randomIndex];
      if (randomItem)  setRandomData(randomItem);
    }, 30000);
    const interval2 = setInterval(() => {
      const date: any = Date.now().toFixed()  
      const date2: any = localStorage.getItem("Date")
      const diff = date - date2;
      const hours = diff / (1000 * 60 * 60);
      if (hours > 24) {
        fetchData(100)
      }
      fetchData(5)
    }, 30000);
    setIntervalIDS([...intervalIDS, interval1]);
    return () => { 
      clearInterval(interval1)
      clearInterval(interval2)
    }
  }, [data]);

  useEffect(() => {
    error && history("/404");
  }, [error]);


  useEffect(() => {
    if (start) {
      fetchData(5);
      setStart(false);
    }
  }, [start]);


  useEffect(() => {
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe(PUSHER_CHANNEL);
    let switchToRandomGallery: any;
    channel.bind(PUSHER_EVENT, (data: any) => {
      if (data?.accepted) {
        localStorage.setItem("Date", Date.now().toFixed());
        setStart(true);
      }
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);


  return <HeroView entry={data} type={HERO_IMAGE.HIGH_RESOLUTION} latestHeroPage />
}
