import React, { useState, useEffect, MouseEventHandler, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getHeroFormListRes, getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { Prop, Entry, ImageData } from "../typescript/pages";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import InfiniteScroll from 'react-infinite-scroll-component';
// import optional lightbox plugins
import { PhotoAlbum, RenderPhoto, Photo } from "react-photo-album";
import Loader from "../components/atoms/loader";
import { HeroEntry } from "../typescript/super-hero-form";
import { uniqBy } from "lodash-es";

interface HeroImagePhoto extends Photo {
  job_title: string;
  name: string;
  entry: HeroEntry
}


export default function Gallery({ entry }: Prop) {
  const history = useNavigate();
  const [getEntry, setEntry] = useState({} as Entry);
  const [getList, setList] = useState<any>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [count, setTotalCount] = useState(0);
  const lpTs = useLivePreviewCtx();
  const [isVisible, setIsVisible] = useState(false);

  const limit = 10;

  const navigateToHero = (uid: any) => {
    // history.
    history('/hero-card', { state: { entry: uid } })
    return
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const renderPhoto: RenderPhoto<HeroImagePhoto> = ({ photo, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
    <div
      className="hero-gallery-container"
      style={{
        width: style?.width,
        padding: `${layoutOptions.padding - 2}px`,
      }}
      onClick={() => navigateToHero(photo.entry)}
    >
      <img alt={alt} className="rounded" style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
      <div className="hero-gallery-title">
        {photo.title}
      </div>
    </div>
  );


  async function fetchData(skip: number) {
    try {
      !infiniteLoading && setInfiniteLoading(true);
      !loading && setLoading(true);
      const heroPage = await getPageRes("/gallery");
      const result = await getHeroFormListRes(skip, limit, "updated_at");
      if(skip === 0)  setTotalCount(result?.[1]);
      (!heroPage || !result) && setError(true);

      // const archive = [] as any;
      const heroList = [] as any;

      result?.[0]
        .filter((image: ImageData) => !!image.aprimo_hero_card_url)
        .forEach((image: ImageData) => {
          const photo = {
            entry: {
              aprimo_hero_card_url: image.aprimo_hero_card_url,
              aprimo_composable_card_url: image.aprimo_composable_card_url,
              aprimo_high_resolution: image.aprimo_high_resolution,
            },
            src: image.aprimo_hero_card_url,
            title: image.name ?? image.title,
            job_title: image.job_title,
            alt: image.uid,
            width: 300,
            height: 300
          } as HeroImagePhoto
          heroList.push(photo);
        });

      setEntry(heroPage);
      const updatedList = uniqBy([...getList, ...heroList], 'alt');
      setList(updatedList);
      // entry({ page: heroPage, blogPost: result });
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false)
      setInfiniteLoading(false);
    }
  }

  useEffect(() => {
    fetchData(skip);
    error && history("/404");
  }, [error, lpTs]);

  // if (loading) return <Loader />


  const handleScroll = () => {
    if (skip + limit < count) {
      setSkip(skip + limit);
      fetchData(skip + limit);
    }
  };

  return (
    <>
      <h1 style={{ fontFamily: "Bangers", textAlign: "center", marginTop: "30px", marginBottom: "10px", letterSpacing: "2px" }}>Hall of Heroes</h1>
      {!infiniteLoading && loading && <Loader />}
      {Object.keys(getEntry).length ? (
        <RenderComponents
          pageComponents={getEntry.page_components}
          blogsPage
          contentTypeUid='page'
          entryUid={getEntry.uid}
          locale={getEntry.locale}
        />
      ) : (
        <Skeleton height={400} />
      )}

      {getList.length > 0 ? (
        <div>
          <InfiniteScroll
            dataLength={getList?.length} //This is important field to render the next data
            next={handleScroll}
            hasMore={skip + limit < count}
            loader={
              <>
                { 
                  infiniteLoading && <h5 style={{ textAlign: 'center' }}>Loading...</h5>
                }
              </>
            }
            endMessage={
              getList?.length === count && (
                <p style={{ textAlign: 'center', marginTop: '10px' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              )
            }
            scrollThreshold={0.4}
          >
            <PhotoAlbum
              layout="rows"
              spacing={5}
              padding={5}
              targetRowHeight={450}
              renderPhoto={renderPhoto}
              photos={getList} 
            />
          </InfiniteScroll>
        </div>
      ) : (
        <div style={{
          maxWidth: "100%",
          minHeight: "100vh",
        }} />
      )}
      {isVisible && (
        <div className="scroll-to-top">
          <button className="scroll-to-top-btn" onClick={scrollToTop}>
            Scroll to top
          </button>
        </div>
      )}
    </>
  );
}
